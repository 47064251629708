import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";

export const frontmatter = {
  title: "Día 36",
  week: "Semana 6",
  month: "abr",
  day: "18",
  monthNumber: "04",
  date: "2020-04-18",
  path: "/cronologia/semana-06#dia-18-abr",
};
const Day36 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModText>
          <strong>NOTA:</strong> Los datos de hoy presentan resultados negativos
          dado que el ISCIII ha decidido excluir de la serie las notificaciones
          de personas con anticuerpos positivos sin síntomas en el momento de
          realización de la prueba en los que no se puede establecer un momento
          de contagio ni si han padecido o no la enfermedad.
        </ModText>
        <ModText>
          La <strong>letalidad del virus</strong> está mostrando ser muy elevada
          en los países del entorno europeo, creciendo de forma especialmente
          rápida en los países del norte europeo.
        </ModText>
        <ModLethalityEu fecha={"2020-04-18"} numPaises={8} />
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-18"} />
      </ContentLeft>

      <ContentRight>
        <ModGraph
          name="18-04-casospercapita-ccaa"
          alt="graficos casos per cápida de covid-19 en españa por cc.aa. el 18 de Abril"
        />
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.658 nuevos positivos y 565 personas fallecidas.
          Permanecen hospitalizadas 75.519 personas, 1.122 más que el día
          anterior, y 1.699 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.553, lo que supone un aumento de 215 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,5 % y la tasa de recuperados del 38,9 %.
        </ModText>
        <ModTwoCols
          src="/images/svg/22_mar_comunidad_madrid.svg"
          alt="personal sanitario afectado por el covid-19"
          small={false}
        >
          Un <strong>19,6%</strong> de los casos notificados a SiViES
          <strong> son personal sanitario</strong>
          (dato calculado sobre los casos que tenían información sobre esta
          variable), siendo significativamente mayor este porcentaje entre las
          mujeres que entre los hombres (27,2 vs 11%).
        </ModTwoCols>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day36;
